import { Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import { breakpoints, colours } from '../styles/stylingVariables'

const Menu = styled.nav`
  display: flex;

  border-bottom: 3px solid ${colours.identity};
  font-size: 18px;
  font-weight: bold;
  margin-top: 1rem;
  justify-content: center;

  @media ${breakpoints.mediumAndAbove} {
    justify-content: flex-start;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  a {
    border: none;
    color: ${colours.black};
    display: inline-block;
    margin: 0;
    padding: 0.25em 0.5em;

    &:hover {
      background-color: ${colours.identity};
      color: ${colours.white};
    }
  }
`

export const Navigation = () => (
  <Menu>
    <ul>
      <li>
        <Link to="/">Home</Link>
        <Link to="/about">About</Link>
      </li>
    </ul>
  </Menu>
)
