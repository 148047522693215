import { Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import logo from '../images/logo.svg'
import { breakpoints } from '../styles/stylingVariables'
import { Navigation } from './Navigation'

const HeaderBlock = styled.header`
  font-family: 'Kontrapunkt Bob', sans-serif;
`

const Branding = styled(Link)`
  display: flex;
  justify-content: center;

  &:link,
  &:visited {
    border: 0px none;
  }

  .logo {
    height: 100px;
    width: auto;
  }

  .site-name {
    font-size: 36px;
    font-weight: bold;
  }

  @media ${breakpoints.mediumAndAbove} {
    justify-content: flex-start;
  }

  @media ${breakpoints.medium} {
    .site-name {
      margin-left: 15px;
      line-height: 100px;
    }
  }

  @media ${breakpoints.fullWidth} {
    .site-name {
      font-size: 72px;
      margin-left: 20px;
      line-height: 100px;
    }
  }
`

const Header = ({ siteTitle }) => (
  <HeaderBlock>
    <Branding to="/">
      <img className="logo" src={logo} alt="" />
      <div className="site-name">{siteTitle}</div>
    </Branding>
    <Navigation />
  </HeaderBlock>
)

export default Header
