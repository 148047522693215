import { graphql, StaticQuery } from 'gatsby'
import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import Helmet from 'react-helmet'
import '../styles/fonts.css'
import '../styles/html.css'
import { Footer } from './Footer'
import Header from './Header'
import { Container } from './styled/Container'

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <Fragment>
        <Helmet title={data.site.siteMetadata.title} meta={[]}>
          <html lang="en" />
        </Helmet>
        <Container>
          <Header siteTitle={data.site.siteMetadata.title} />
          {children}
          <Footer />
        </Container>
      </Fragment>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
