import React from 'react'
import Layout from '../components/Layout'

const NotFoundPage = () => (
  <Layout>
    <h1>404 NOT FOUND</h1>
    <p>Sorry, but this page does not exist.</p>
  </Layout>
)

export default NotFoundPage
