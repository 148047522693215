import { Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import { colours } from '../styles/stylingVariables'

const StyledFooter = styled.footer`
  border-top: 3px solid ${colours.identity};
  font-size: 12px;
  display: flex;
  margin-top: 1rem;
  padding-top: 1rem;
`

const FooterContactInfo = styled.div`
  width: 50%;
`

const FooterNavigation = styled.nav`
  text-align: right;
  width: 50%;

  ul {
    margin: 0;
    padding: 0;
  }

  li {
    display: inline-block;
    list-style-type: none;
  }

  a {
    border-bottom-width: 0;

    &:hover {
      color: ${colours.identity};
      text-decoration: underline;
    }
  }
`

export const Footer = () => (
  <StyledFooter>
    <FooterContactInfo>
      <div class="h-card">
        <div class="p-name">Högh Digital</div>
        <div class="p-adr h-adr">
          ℅ Mikkel Högh
          <div class="p-street-address">Industriestrasse 54</div>
          <span class="p-postal-code">6300</span>&nbsp;
          <span class="p-locality">Zug</span>
          <div class="p-country-name">Switzerland</div>
        </div>
      </div>
    </FooterContactInfo>
    <FooterNavigation role="navigation">
      <ul class="menu">
        <li>
          <Link to="/credits" title="The tools used to build this site">
            Credits
          </Link>
        </li>
      </ul>
    </FooterNavigation>
  </StyledFooter>
)
