export const breakpoints = {
  narrow: `(min-width: 699px)`,
  medium: `(min-width: 700px) and (max-width: 959px)`,
  mediumAndAbove: `(min-width: 700px)`,
  fullWidth: `(min-width: 960px)`
}

export const identityColour = 'hsl(157, 100%, 34%)'

export const colours = {
  black: 'hsl(157, 10%, 20%)',
  identity: 'hsl(157, 100%, 34%)',
  white: 'hsl(157, 10%, 98%)'
}
